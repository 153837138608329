body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.diplom {
  font-family: RobotoC;
  color: #005e7a;
  font-size: 20px;
  padding: 8px 0;
  margin: 48px 0 0 0;
  text-transform: uppercase;
  border-bottom: solid 4px #00aeef;
  /* max-width: 220px; */
}

.laureat {
  font-family: RobotoB;
  font-size: 32px;
  color: #005e7a;
  margin: 32px 0 0 0;
}

.birth {
  font-family: RobotoL;
  font-size: 16px;
  margin: 8px 0 0 0;
}

.period {
  font-family: RobotoB;
  font-size: 16px;
  margin: 16px 0 0 0;
}

.degree-name {
  font-size: 32px;
  color: #00aeef;
  margin: 16px 0 0 0;
  line-height: 32px;
  font-family: RobotoB;
  text-align: center;
}

.annee {
  font-family: RobotoL;
  font-size: 16px;
  margin: 16px 0 0 0;
  font-weight: bold;
}

.signature {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 3rem;
}

.sign {
  display: flex;
  flex-direction: column;
}

.author {
  color:#005e7a;
  font-weight: bold;
}



.certificate {
  /* background-image: url(./assets/images/cert-bg-5.jpeg); */
  background-color: white;
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  background-repeat: no-repeat;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.certificate .header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
  body {
    overflow: scroll !important;
  }

  .certificate {
    text-align: center;
  }
  .header1 {
    width: 100%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    text-align: center;
  }

  .qrcode {
    width: 5rem;
    height: 5rem;
  }

  .signature {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
  }
}

/* Media query for screens larger than or equal to 768px */
@media (min-width: 768px) {
  .header1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.qrcode {
  /* height: 2rem;
  width: 2rem; */
}

a, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}

.cert-status::before {
  content:" • ";
}

.color-chart {
  color: rgb(68, 89, 110);
}

.splitted-view {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
} 

@media (max-width: 700px) {
  .cert-prev {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    item-2 {
      display: flex;
      flex-direction: column;
    }
  }

  .select-org {
    text-align: center;
    margin-top: 2rem;

    .item-2 {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }
  }

  .register {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .register img {
    width: 30%;
    margin-bottom: 2rem;
  }

  .splitted-view {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    overflow-x: hidden;

    .grid-item-1 {
      text-align: center;
      height: 60%;
      display: none;
    }
    
    .grid-item-2 {
      display: flex;
      text-align: center;
      width: 100%;
    }
  } 
}